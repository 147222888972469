import axios from '../lib/axios';

export function getTestPlans() {
    return axios.get('/api/test-management/test-plans')
        .then(response => response.data);
}

export function deleteTestPlan(id) {
    return axios.delete(`/api/test-management/test-plans/${id}`)
        .then(response => response.data);
}

export function createTestPlan({ name, description, testCases }) {
    return axios.post(`/api/test-management/test-plans`, {
        name, description
    })
        .then(response => response.data._id)
        .then((testPlanId) => {
            return Promise.all(testCases.map(testCase => {
                return axios.post(`/api/test-management/test-plans-test-cases`, {
                    test_plan_id: testPlanId,
                    test_case_id: testCase._id,
                })
            }))
        });
}

export function updateTestPlan({ id, name, description, originalTestCases, testCases }) {
    return axios.put(`/api/test-management/test-plans/${id}`, {
        name,
        description
    })
        .then(response => response.data._id)
        .then((testPlanId) => {
            return Promise.all(originalTestCases.map(testCase => {
                return axios.delete(`/api/test-management/test-plans/${id}/test-plans-test-cases/${testCase._id}`)
            })).then(() => {
                return Promise.all(testCases.map(testCase => {
                    return axios.post(`/api/test-management/test-plans-test-cases`, {
                        test_plan_id: testPlanId,
                        test_case_id: testCase._id,
                    })
                }))
            });
        });
}

export function getTestPlan({ id }) {
    return axios.get(`/api/test-management/test-plans/${id}`)
        .then(response => {
            const testPlan = response.data;

            return axios.get(`/api/test-management/test-plans/${id}/test-plans-test-cases`)
                .then(responseTestCases => {
                    return {
                        ...testPlan,
                        testCases: responseTestCases.data,
                    }
                });
        });
}