import { HashRouter, Routes, Route } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  defaultContext, 
} from '@tanstack/react-query'

import './App.css';

import List from './components/list';
import Form from './components/form';
import TestPlan from "./components/test-plan";

const queryClient = new QueryClient({
  context: defaultContext,
})

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient} context={defaultContext}>
        <HashRouter>
          <Routes>
            <Route
              path=""
              element={<List />} 
            />
            <Route 
              path="/test-plans/new"
              element={<Form />}
            />
            <Route
              path="/test-plans/:id"
              element={<TestPlan />}
            />
            <Route 
              path="/test-plans/:id/edit"
              element={<Form />}
            />
          </Routes>
        </HashRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
