/**
 * third party dependencies
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultContext } from '@tanstack/react-query'

/**
 * labqube components
 */
import { Message, PageHeader, Table, Button, Input, Loading, LastUpdateBy } from '@labqube/components';

/**
 * services
 */
import {deleteTestPlan, getTestPlans} from '../services/test-plans';

import './list.css';

const List = () => {
    const [testPlans, setTestPlans] = useState([]);
    const [filteredTestPlans, setFilteredTestPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorState, setErrorState] = useState(false);
    const [filter, setFilter] = useState('');

    function refresh() {
        getTestPlans().then(testPlans => {
            setTestPlans(testPlans);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            setErrorState(true);
        });
    }

    function deleteItem(item) {
        const result = window.confirm('Do you want to delete this test plan and its contents?');

        if (result) {
            deleteTestPlan(item._id).then(() => {
                refresh();
            }).catch((e) => {
                console.log(e);
                // TODO https://labqube.atlassian.net/browse/COM-4
            });
        }
    }

    useEffect(() => {
        setFilteredTestPlans(
            testPlans.filter(t => {
                return t.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            }).map(item => {
                return {
                    id: item._id,
                    content: {
                        name: (
                            <Link 
                                to={`/test-plans/${item._id}`}
                                style={{ color: 'black', wordBreak: 'break-word' }}
                            >
                                {item.name}
                            </Link>
                        ),
                        description: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ item.description || '-' }</span>,
                        'last update by': <LastUpdateBy accountId={item.user_account_id} queryClientContext={defaultContext} />,
                        actions: (
                            <div>
                                <Link 
                                    to={`/test-plans/${item._id}/edit`}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                    <i className="fa-solid fa-pen-to-square clickable"></i>
                                </Link>
                                &nbsp;
                                <i className="fa-solid fa-trash clickable" onClick={() => deleteItem(item)}></i>
                            </div>
                        )
                    },
                }
            })
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testPlans, filter]);

    useEffect(() => {
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Loading size='large'>
                <p>
                    Loading test plans...
                </p>
            </Loading>
        )
    }

    if (errorState) {
        return (
            <div style={{ margin: 41 }}>
                <Message
                    title={'Oh no! Something went wrong'}
                    description={'Try reloading the page or contact support if the error persists'}
                />
            </div>
        )
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'Test plans'}
                style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
                breadcrumbs={[{
                    href: '',
                    text: 'Test plans'
                }]}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input 
                            style={{ marginRight: 10 }} 
                            placeholder='Search test plans'
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                            }}
                        />
                        {
                            testPlans.length !== 0 && (
                                <Link to={'/test-plans/new'} style={{ width: '100%' }}>
                                    <Button type={'primary'} style={{height: 37}}>
                                        Add test plan
                                    </Button>
                                </Link>
                            )
                        }
                    </div>
                }
            />
            {
                !filteredTestPlans.length && (
                    <Message
                        title={'No test plans to show'}
                        description={'Try creating a new one!'}
                        actionComponent={
                            <Link to={'/test-plans/new'}>
                                <Button type="primary">
                                    Let's start!
                                </Button>
                            </Link>
                        }
                    />
                )
            }
            {
                filteredTestPlans.length !== 0 && (
                    <Table
                        headers={['Name', 'Description', 'Last update by', 'Actions']}
                        columnsWidth={['30%', '30%', '20%', '10%']}
                        items={filteredTestPlans}
                    />
                )
            }
        </div>
    )
}

export default List;
